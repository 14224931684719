import {
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  OnDestroy,
} from '@angular/core';

// services
import { LogoutService } from 'src/app/common/service/logout.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { CommonService } from '../../../common/service/common.service';
// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { ThemeService } from '../../../common/service/theme/theme.service';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [],
})
export class HeaderComponent implements OnInit, OnDestroy {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public dropdown: boolean = false;
  public firstname: any;
  public lastname: any;
  public photo: any;
  public theme: string = null;
  public alignment: string = null;
  public status: boolean = false;
  passwordTypeOld: boolean;
  passwordTypeNew: boolean;
  passwordType: boolean;
  public passdata: any = {};
  public submitted = false;
  modalRef: BsModalRef;
  fieldTextType: boolean;
  notificationCount: any = 0;

  constructor(
    private localStorageService: LocalStorageService,
    private themeService: ThemeService,
    private logoutService: LogoutService,
    private showErrorService: ShowErrorService,
    public broadCasterService: BroadcasterService,
    private toastr: ToastrService,
    public router: Router,
    public translate: TranslateService,
    private commonService: CommonService,
    private modalService: BsModalService
  ) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  ngOnInit(): void {
    var isDark =
      this.localStorageService.getToken('theme') == 'Dark' ? true : false;
    var isHorizontal =
      this.localStorageService.getToken('menuAlignment') == 'Horizontal'
        ? true
        : false;

    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document
        .getElementById('customSwitchHeaderTheme')
        .setAttribute('checked', '');

    this.themeService.switchAlignmentAfterLogin(isHorizontal);
    if (isHorizontal)
      document.getElementById('sidebarSwitchBtn').setAttribute('checked', '');
    this.countList();
  }
  ngOnDestroy() {}

  @HostListener('window:scroll', [])
  onWindowScroll(event: Event) {
    //set up the div "id=nav"
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      document.getElementById('header').classList.add('sticky');
    } else {
      document.getElementById('header').classList.remove('sticky');
    }
  }

  /*************************************************************
  @Purpose     : Call API to Change Password
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  public showErr = false;
  changePassword(passwordForm) {
    this.submitted = true;

    if (passwordForm.valid) {
      const data = {
        oldPassword: passwordForm.value.oldPassword,
        newPassword: passwordForm.value.newPassword,
      };
      if (
        passwordForm.value.newPassword !== passwordForm.value.confirmPassword
      ) {
        this.showErr = true;
        this.submitted = true;
      } else {
        this.showErr = false;
        this.commonService
          .callApi(
            this.callAPIConstants.AdminChangePassword,
            data,
            'post',
            false,
            false
          )
          .then((success) => {
            if (success.status === 1) {
              this.modalService.hide();
              this.toastr.success('success', success.message);
            } else {
              this.toastr.error('error', success.message);
              this.modalService.hide();
            }
          });
      }
    }
  }
  /*************************************************************/

  toggleFieldTextTypeOld() {
    this.passwordTypeOld = !this.passwordTypeOld;
  }

  toggleFieldTextTypeNew() {
    this.passwordTypeNew = !this.passwordTypeNew;
  }

  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }

  logout() {
    this.logoutService.logout();
  }

  showHideDropdown() {
    this.dropdown = !this.dropdown;
  }

  clickEvent() {
    this.status = !this.status;
  }

  openSidebar() {
    var sidebar = Array.from(
      document.getElementsByClassName(
        'sidebar'
      ) as HTMLCollectionOf<HTMLElement>
    );
    sidebar[0].style.display = 'block';
  }

  ngDoCheck(): void {
    this.firstname = this.localStorageService.getToken('firstname');
    this.lastname = this.localStorageService.getToken('lastname');
    this.photo = this.localStorageService.getToken('photo');
  }

  /*************************************************************
	 @Purpose     : Get Profile Image
	 @Parameter   : NA
	 @Return      : url
	/*************************************************************/
  getProfile() {
    const url = this.localStorageService.getToken('photo');
  }
  /****************************************************************************/

  // toggle light/dark theme
  toggleTheme() {
    this.theme = this.themeService.switchTheme();
    this.alignment =
      this.localStorageService.getToken('menuAlignment') != null
        ? this.localStorageService.getToken('menuAlignment')
        : null;

    var obj = {
      theme: this.theme,
      menuAlignment: this.alignment,
    };
    this.commonService
      .callApi(this.callAPIConstants.UpdateTheme, obj, 'post', false, false)
      .then((success) => {
        if (success.status === 1) {
          this.localStorageService.setToken('theme', success.data.theme);
          this.localStorageService.setToken(
            'menuAlignment',
            success.data.menuAlignment
          );
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  // toggle Alignment
  toggleAlignment() {
    this.alignment = this.themeService.switchAlignment();
    this.theme =
      this.localStorageService.getToken('theme') != null
        ? this.localStorageService.getToken('theme')
        : null;
    var obj = {
      theme: this.theme,
      menuAlignment: this.alignment,
    };
    this.commonService
      .callApi(this.callAPIConstants.UpdateTheme, obj, 'post', false, false)
      .then((success) => {
        if (success.status === 1) {
          this.localStorageService.setToken('theme', success.data.theme);
          this.localStorageService.setToken(
            'menuAlignment',
            success.data.menuAlignment
          );
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }
  /*************************************************************
  @Purpose     : Get List of Count on Login
  @Parameter   : value
  @Return      : items
  /*************************************************************/
  datalist: any = [];
  listingData: any = [];
  countList() {
    let obj = {
      page: 1,
      pagesize: 10,
    };
    this.commonService
      .callApi(
        this.callAPIConstants.NotificationList,
        obj,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          success.data.listing.map((data) => {
            if (!data.isRead) {
              this.datalist.push(data);
            }
          });
          this.notificationCount = success.unreadNotificationCount;
        }
      });
  }
  /*************************************************************
  @Purpose     : Get Notification of user
  @Parameter   : value
  @Return      : items
  /*************************************************************/
  changeNotification(event, item) {
    let data: any = [];
    data.push(item._id);
    let obj = this.datalist.find((o) => o._id === data[0]);
    let dataobj = {
      notificationIds: data,
    };
    if (!item.isRead) {
      this.commonService
        .callApi(
          this.callAPIConstants.ReadNotification,
          dataobj,
          'post',
          false,
          false
        )
        .then((success) => {
          if (success.status === 1) {
            this.datalist = [];
            this.notificationCount = 0;
            this.countList();
            if (obj.title === 'Solution Reported') {
              this.router.navigate([
                URLConstants.SOLUTION_REPORT,
                obj.solutionId,
              ]);
            } else if (obj.title === 'Incident Reported') {
              this.router.navigateByUrl(URLConstants.REPORT, {
                state: { incidentId: obj.incidentId._id },
              });
            } else if (
              obj.title === 'Incident Liked' ||
              obj.title === 'Incident Added' ||
              obj.title === 'Admin Added Incident' ||
              obj.title === 'Incident Approved' ||
              obj.title === 'Edit Incident'
            ) {
              this.router
                .navigateByUrl('/Dashboards', { skipLocationChange: true })
                .then(() =>
                  this.router.navigateByUrl(
                    obj.incidentId.industry === 'healthcare'
                      ? URLConstants.INCIDENTS
                      : URLConstants.SIDEBARAVIATIONINCIDENTS,
                    {
                      state: { incidentId: obj.incidentId._id },
                    }
                  )
                );
            } else if (
              obj.title === 'Solution Approved' ||
              obj.title === 'Admin Added Solution' ||
              obj.title === 'Solution Added' ||
              obj.title === 'Solution Liked' ||
              obj.title === 'Edit Solution'
            ) {
              this.router.navigateByUrl(URLConstants.SOLUTIONS, {
                state: { solutionId: obj.solutionId },
              });
            } else if (obj.title === 'Solution Rejected') {
              this.router.navigate([URLConstants.SOLUTION_REPORT]);
            } else if (
              obj.title === 'Delete Incident Request' ||
              obj.title === 'Incident Rejected'
            ) {
              this.router.navigate([
                URLConstants.INCIDENTS_DELETE,
                obj.incidentId._id,
              ]);
            } else if (obj.title === 'Delete Solution Request') {
              this.router.navigate([
                URLConstants.SOLUTIONS_DELETE,
                obj.solutionId,
              ]);
            } else if (
              obj.title === 'Domain Recommendation' ||
              obj.title === 'Domain'
            ) {
              this.router.navigateByUrl(URLConstants.RECOMMANDED_DOMAIN, {
                state: { domainName: obj.notificationMessage.split(' ')[3] },
              });
            } else if (obj.title === 'Feedback Added') {
              this.router.navigateByUrl(URLConstants.REACH_US);
            } else {
              return null;
            }
          } else {
            this.toastr.error('Error', 'server error');
          }
        });
    }
  }
}
