<aside class="sidebar">
  <div class="sidebar-inner-wrapper">
    <div
      class="sidebar-heading d-flex align-items-center justify-content-center w-100"
      style="background: #fff"
    >
      <a class="navbar-brand m-0">
        <div class="brand-logo">
          <img
            style="width: 100px"
            class="img-fluid"
            src="assets/images/logofooter.png"
            alt="branding logo"
          />
          <div class="d-flex align-items-center justify-content-center">
            <p class="m-0 text-center" style="font-size: 14px; color: #373535">
              {{ industry == 'healthcare' ? 'Healthcare' : 'Aviation' }}
            </p>
          </div>
        </div>
      </a>
      <span (click)="closeSidebar('')" class="sidebar-close-icon"
        ><em class="bx bx-x"></em
      ></span>
    </div>
    <div class="sidebar-menu">
      <ul class="sidebar-menu-list">
        <li class="menu-list-item has-child-submenu level1">
          <a
            routerLinkActive="active"
            id="dashboard"
            (click)="openCloseSubMenu('dashboard')"
            href="#"
            class="active-link menu-link"
          >
            <em class="bx bx-home-heart"></em>
            <span class="menu-title">Dashboard</span>
          </a>
        </li>

        <li
          *ngIf="userAccess || adminUserAccess"
          class="menu-list-item has-child-submenu level1"
          (click)="openCloseSubMenu('subUserMenu')"
          [ngClass]="subUserMenu ? 'submenu-open' : null"
        >
          <a
            routerLinkActive="active"
            style="cursor: pointer"
            class="menu-link"
          >
            <em class="bx bxs-user"></em>
            <span class="menu-title">User Management</span>
            <em class="bx bxs-chevron-right"></em>
          </a>
          <ul
            class="sidebar-menu-list sub-menu-list"
            [ngClass]="subUserMenu ? 'show' : null"
          >
            <li
              *ngIf="userAccess && industry == 'healthcare'"
              class="menu-list-item"
            >
              <a
                routerLinkActive="dropdown-active"
                id="users"
                (click)="closeSidebar('subUserMenu')"
                [routerLink]="[URLConstants.USERS]"
                class="menu-link"
              >
                <em class="bx bxs-right-arrow-alt active-icon"></em>
                <span class="menu-title active-title">Healthcare Users</span>
              </a>
            </li>
            <li
              *ngIf="userAccess && industry == 'aviation'"
              class="menu-list-item"
            >
              <a
                routerLinkActive="dropdown-active"
                id="users"
                (click)="closeSidebar('subUserMenu')"
                [routerLink]="[URLConstants.AVIATIONUSERS]"
                class="menu-link"
              >
                <em class="bx bxs-right-arrow-alt active-icon"></em>
                <span class="menu-title active-title">Aviation Users</span>
              </a>
            </li>
            <li *ngIf="adminUserAccess" class="menu-list-item">
              <a
                id="adminUsers"
                (click)="closeSidebar('subUserMenu')"
                routerLinkActive="dropdown-active"
                [routerLink]="[URLConstants.ADMINUSERS]"
                class="menu-link"
              >
                <em class="bx bxs-right-arrow-alt active-icon"></em>
                <span class="menu-title active-title">Admin users</span>
              </a>
            </li>
          </ul>
        </li>

        <li *ngIf="cmsPagesAccess" class="menu-list-item">
          <a
            id="content"
            routerLinkActive="active"
            [routerLink]="[URLConstants.CONTENT]"
            (click)="openCloseSubMenu('dashboard')"
            class="menu-link"
          >
            <i class="bx bx-barcode-reader"></i>
            <span class="menu-title">Content Management</span>
          </a>
        </li>
        <li *ngIf="rolesAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.MANAGE_ROLES]"
            class="menu-link"
          >
            <em class="bx bx-cycling"></em>
            <span class="menu-title">Role Management</span>
          </a>
        </li>
        <li *ngIf="regionManagementAccess" class="menu-list-item">
          <a
            id="region"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.REGION]"
            class="menu-link"
          >
            <em class="bx bx-map-alt"></em>
            <span class="menu-title">Region Management</span>
          </a>
        </li>
        <li *ngIf="subRegionManagementAccess" class="menu-list-item">
          <a
            id="sub-region"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.SUB_REGION]"
            (click)="openCloseSubMenu('dashboard')"
            class="menu-link"
          >
            <em class="bx bxs-barcode"></em>
            <span class="menu-title">Sub-Region Management</span>
          </a>
        </li>

        <li
          *ngIf="incidentManagementAccess || requestedDeleteIncidentAccess"
          class="settign-menu menu-list-item has-child-submenu level1"
          (click)="openCloseSubMenu('incidentMenu')"
          [ngClass]="incidentMenu ? 'submenu-open' : null"
        >
          <a
            routerLinkActive="active"
            style="cursor: pointer"
            class="menu-link"
          >
            <!-- <em class='bx bxs-pie-chart-alt-2'></em> -->
            <em class="bx bxs-traffic-barrier"></em>
            <span class="menu-title">Incident </span>
            <em class="bx bxs-chevron-right"></em>
          </a>
          <ul
            class="sidebar-menu-list sub-menu-list"
            [ngClass]="incidentMenu ? 'show' : null"
          >
            <li
              *ngIf="incidentManagementAccess && industry == 'healthcare'"
              class="menu-list-item"
            >
              <a
                (click)="closeSidebar('incidentMenu')"
                routerLinkActive="dropdown-active"
                id="incidentlist"
                [routerLink]="[URLConstants.SIDEBARINCIDENTS]"
                class="menu-link"
              >
                <em class="bx bxs-timer active-icon"></em>
                <span class="menu-title active-title"
                  >Healthcare Incident List
                </span>
              </a>
            </li>
            <li
              *ngIf="incidentManagementAccess && industry == 'aviation'"
              class="menu-list-item"
            >
              <a
                (click)="closeSidebar('incidentMenu')"
                routerLinkActive="dropdown-active"
                id="incidentlist"
                [routerLink]="[URLConstants.SIDEBARAVIATIONINCIDENTS]"
                class="menu-link"
              >
                <em class="bx bxs-timer active-icon"></em>
                <span class="menu-title active-title"
                  >Aviation Incident List
                </span>
              </a>
            </li>
            <li *ngIf="requestedDeleteIncidentAccess" class="menu-list-item">
              <a
                (click)="closeSidebar('incidentMenu')"
                routerLinkActive="dropdown-active"
                [routerLink]="[URLConstants.INCIDENTS_DELETE, null]"
                class="menu-link"
              >
                <em class="bx bxs-no-entry active-icon"></em>
                <span class="menu-title active-title">Requested Delete </span>
              </a>
            </li>
          </ul>
        </li>

        <li
          *ngIf="solutionManagementAccess || requestedDeleteSolutionAccess"
          class="settign-menu menu-list-item has-child-submenu level1"
          (click)="openCloseSubMenu('solutionMenu')"
          [ngClass]="solutionMenu ? 'submenu-open' : null"
        >
          <a
            routerLinkActive="active"
            style="cursor: pointer"
            class="menu-link"
          >
            <em class="bx bx-layer"></em>
            <span class="menu-title">Solution </span>
            <em class="bx bxs-chevron-right"></em>
          </a>
          <ul
            class="sidebar-menu-list sub-menu-list"
            [ngClass]="solutionMenu ? 'show' : null"
          >
            <li *ngIf="solutionManagementAccess" class="menu-list-item">
              <a
                (click)="closeSidebar('solutionMenu')"
                routerLinkActive="dropdown-active"
                id="solutionlist"
                [routerLink]="[URLConstants.SIDEBARSOLUTIONS]"
                class="menu-link"
              >
                <em class="bx bxs-timer active-icon"></em>
                <span class="menu-title active-title">Solution List </span>
              </a>
            </li>
            <li *ngIf="requestedDeleteSolutionAccess" class="menu-list-item">
              <a
                (click)="closeSidebar('solutionMenu')"
                routerLinkActive="dropdown-active"
                [routerLink]="[URLConstants.SOLUTIONS_DELETE, null]"
                class="menu-link"
              >
                <em class="bx bxs-no-entry active-icon"></em>
                <span class="menu-title active-title">Requested Delete </span>
              </a>
            </li>
          </ul>
        </li>

        <!-- <li
          *ngIf="solutionManagementAccess"
          class="menu-list-item"
          (click)="openCloseSubMenu('dashboard')"
        >
          <a
            id="solution"
            routerLinkActive="active"
            [routerLink]="[URLConstants.SOLUTIONS]"
            class="menu-link"
          >
            <em class="bx bx-layer"></em>

            <span class="menu-title">Solution Management </span>
          </a>
        </li> -->
        <li
          class="settign-menu menu-list-item has-child-submenu level1"
          (click)="openCloseSubMenu('subReportMenu')"
          [ngClass]="subReportMenu ? 'submenu-open' : null"
        >
          <a
            routerLinkActive="active"
            style="cursor: pointer"
            class="menu-link"
          >
            <em class="bx bxs-pie-chart-alt-2"></em>
            <span class="menu-title">Reported content</span>
            <em class="bx bxs-chevron-right"></em>
          </a>
          <ul
            class="sidebar-menu-list sub-menu-list"
            [ngClass]="subReportMenu ? 'show' : null"
          >
            <li class="menu-list-item">
              <a
                routerLinkActive="dropdown-active"
                (click)="
                  closeSidebarMenuItem('content'); closeSidebar('subReportMenu')
                "
                [routerLink]="[URLConstants.SIDEBARREPORT]"
                class="menu-link"
              >
                <em class="bx bxs-right-arrow-alt active-icon"></em>
                <span class="menu-title active-title">Incident Report</span>
              </a>
            </li>
            <li class="menu-list-item">
              <a
                (click)="closeSidebar('subReportMenu')"
                routerLinkActive="dropdown-active"
                [routerLink]="[URLConstants.SOLUTION_REPORT, null]"
                class="menu-link"
              >
                <em class="bx bxs-right-arrow-alt active-icon"></em>
                <span class="menu-title active-title">Solution Report</span>
              </a>
            </li>
          </ul>
        </li>

        <li *ngIf="domainManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.DOMAIN_COMPANIES]"
            class="menu-link"
          >
            <!-- <em domainManagementAccess class='bx bx-note'></em> -->
            <em class="bx bxs-component"></em>
            <span class="menu-title">Domain/Companies</span>
          </a>
        </li>
        <li *ngIf="recommendedDomainManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.RECOMMANDED_DOMAIN]"
            class="menu-link"
          >
            <!-- <em domainManagementAccess class='bx bx-note'></em> -->
            <em class="bx bx-shield-alt-2"></em>
            <span class="menu-title">Recommended Domains</span>
          </a>
        </li>
        <li *ngIf="departmentManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.DEPARTMENTS]"
            class="menu-link"
          >
            <em class="bx bx-bug-alt"></em>
            <span class="menu-title">Departments</span>
          </a>
        </li>
        <li *ngIf="designationManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.DESIGNTION]"
            class="menu-link"
          >
            <em class="bx bx-chair"></em>
            <span class="menu-title">Designations</span>
          </a>
        </li>

        <li *ngIf="healthcareRoleManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.HEALTHCAREROLE]"
            class="menu-link"
          >
            <em class="bx bx-bed"></em>
            <span class="menu-title">Healthcare Roles</span>
          </a>
        </li>
        <li *ngIf="positionManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.POSITION]"
            class="menu-link"
          >
            <em class="bx bx-award"></em>
            <span class="menu-title">Positions</span>
          </a>
        </li>

        <li *ngIf="expertiseManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.EXPERTISE]"
            class="menu-link"
          >
            <em class="bx bx-list-check"></em>
            <span class="menu-title">Expertise</span>
          </a>
        </li>
        <li *ngIf="trainingManagementAccess" class="menu-list-item">
          <a
            id="region"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.TRAINING]"
            class="menu-link"
          >
            <em class="bx bx-building-house"></em>
            <span class="menu-title">Training</span>
          </a>
        </li>
        <li *ngIf="mechanicalFailureManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.MECHANICALFAILURE]"
            class="menu-link"
          >
            <em class="bx bx-wrench"></em>
            <span class="menu-title">Mechanical Failures</span>
          </a>
        </li>
        <li *ngIf="airlineManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.AIRLINE]"
            class="menu-link"
          >
            <em class="bx bxs-plane"></em>
            <span class="menu-title">Airline</span>
          </a>
        </li>
        <li *ngIf="placeOfServiceManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.SERVICE]"
            class="menu-link"
          >
            <em class="bx bxs-building"></em>
            <span class="menu-title">Place of Service</span>
          </a>
        </li>
        <li *ngIf="newsManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.NEWS]"
            class="menu-link"
          >
            <em class="bx bx-chair"></em>
            <span class="menu-title">News</span>
          </a>
        </li>
        <li *ngIf="blogsManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.BLOGSMNG]"
            class="menu-link"
          >
            <em class="bx bx-book"></em>
            <span class="menu-title">Blogs</span>
          </a>
        </li>
        <li *ngIf="tagManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.TAG]"
            class="menu-link"
          >
            <em class="bx bxs-hot bx-tada-hover"></em>
            <span class="menu-title">Tags</span>
          </a>
        </li>
        <li *ngIf="reachUsManagementAccess" class="menu-list-item">
          <a
            id="master"
            routerLinkActive="active"
            (click)="openCloseSubMenu('dashboard')"
            [routerLink]="[URLConstants.REACH_US]"
            class="menu-link"
          >
            <em class="bx bxs-landmark"></em>
            <span class="menu-title">Reach Management</span>
          </a>
        </li>

        <li
          class="settign-menu menu-list-item has-child-submenu level1"
          (click)="openCloseSubMenu('postMenu')"
          [ngClass]="postMenu ? 'submenu-open' : null"
          *ngIf="postManagementAccess"
        >
          <a
            routerLinkActive="active"
            style="cursor: pointer"
            class="menu-link"
          >
            <!-- <em class='bx bxs-pie-chart-alt-2'></em> -->
            <em class="bx bxs-book-add"></em>
            <span class="menu-title">Post Management</span>
            <em class="bx bxs-chevron-right"></em>
          </a>
          <ul
            class="sidebar-menu-list sub-menu-list"
            [ngClass]="postMenu ? 'show' : null"
          >
            <li *ngIf="postManagementAccess" class="menu-list-item">
              <a
                (click)="closeSidebar('postMenu')"
                routerLinkActive="dropdown-active"
                id="incidentlist"
                [routerLink]="[URLConstants.POSTS]"
                class="menu-link"
              >
                <em class="bx bxs-pen active-icon"></em>
                <span class="menu-title active-title">Post List </span>
              </a>
            </li>
            <li *ngIf="postManagementAccess" class="menu-list-item">
              <a
                (click)="closeSidebar('postMenu')"
                routerLinkActive="dropdown-active"
                [routerLink]="[URLConstants.INFOGRAPHICS]"
                class="menu-link"
              >
                <em class="bx bxs-chart active-icon"></em>
                <span class="menu-title active-title">Infographic List </span>
              </a>
            </li>
          </ul>
        </li>

        <li
          *ngIf="preApprovedEmailAccess || preApprovedEmailTagsAccess"
          class="settign-menu menu-list-item has-child-submenu level1"
          (click)="openCloseSubMenu('emailMenu')"
          [ngClass]="emailMenu ? 'submenu-open' : null"
        >
          <a
            routerLinkActive="active"
            style="cursor: pointer"
            class="menu-link"
          >
            <!-- <em class='bx bxs-pie-chart-alt-2'></em> -->
            <em class="bx bx-envelope"></em>
            <span class="menu-title">Pre-approved emails</span>
            <em class="bx bxs-chevron-right"></em>
          </a>
          <ul
            class="sidebar-menu-list sub-menu-list"
            [ngClass]="emailMenu ? 'show' : null"
          >
            <li *ngIf="preApprovedEmailTagsAccess" class="menu-list-item">
              <a
                (click)="closeSidebar('emailMenu')"
                routerLinkActive="dropdown-active"
                id="incidentlist"
                [routerLink]="[URLConstants.EMAILTAGS]"
                class="menu-link"
              >
                <em class="bx bxs-purchase-tag active-icon"></em>
                <span class="menu-title active-title">Email Tags</span>
              </a>
            </li>
            <li *ngIf="preApprovedEmailAccess" class="menu-list-item">
              <a
                (click)="closeSidebar('emailMenu')"
                routerLinkActive="dropdown-active"
                [routerLink]="[URLConstants.EMAILS]"
                class="menu-link"
              >
                <em class="bx bxs-file-plus active-icon"></em>
                <span class="menu-title active-title">Emails </span>
              </a>
            </li>
          </ul>
        </li>

        <li *ngIf="commentManagementAccess" class="menu-list-item">
          <a
            id="content"
            routerLinkActive="active"
            [routerLink]="[URLConstants.COMMENTS]"
            (click)="openCloseSubMenu('dashboard')"
            class="menu-link"
          >
            <i class="bx bx-comment-minus"></i>
            <span class="menu-title">Comment Management</span>
          </a>
        </li>

        <li *ngIf="qrManagementAccess" class="menu-list-item">
          <a
            id="content"
            routerLinkActive="active"
            [routerLink]="[URLConstants.QRCAMPAIGNS]"
            (click)="openCloseSubMenu('dashboard')"
            class="menu-link"
          >
            <i class="bx bx-barcode"></i>
            <span class="menu-title">Qr Management</span>
          </a>
        </li>
        <li *ngIf="replyManagementAccess" class="menu-list-item">
          <a
            id="content"
            routerLinkActive="active"
            [routerLink]="[URLConstants.REPLIES]"
            (click)="openCloseSubMenu('dashboard')"
            class="menu-link"
          >
            <i class="bx bx-reply"></i>
            <span class="menu-title">Reply Management</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</aside>
