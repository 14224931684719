export const callAPIConstants = {
  AdminLogin: 'admin/login',
  AdminRegister: 'admin/register',
  VerifyOTP: 'admin/verifyOTP',
  SendOTP: 'admin/sendOTP',
  sendOTPChangeMoble: 'admin/sendOTPChangeMobile',
  verifyOTPChangeMobile: 'admin/verifyOTPChangeMobile',
  ForgotPassword: 'admin/forgotPassword',
  ResetPassword: 'admin/resetPassword',
  SetPassword: 'admin/setPassword',
  AdminLogout: 'admin/logout',
  AdminGetProfile: 'admin/profile',
  AdminEditProfile: 'admin/editProfile',
  AdminFileUpload: 'admin/fileUpload',
  AdminChangePassword: 'admin/changePassword',
  UpdateTheme: 'admin/updateThemeDetails',
  CountriesListing: 'countriesListing',
  getCountryDetails: 'getCountryDetails',
  getCurrencyDetails: 'getCurrencyDetails',
  getTimezoneDetails: 'getTimezoneDetails',
  getCountriesList: 'getCountriesList',
  addUpdateCountry: 'addUpdateCountry',
  addUpdateCurrency: 'addUpdateCurrency',
  addUpdateTimezone: 'addUpdateTimezone',
  changeCountriesStatus: 'changeCountriesStatus',
  deleteCountries: 'deleteCountries',
  deleteRoles: 'deleteRoles',
  timezoneListing: 'timezoneListing',
  changeTimezoneStatus: 'changeTimezoneStatus',
  deleteTimezones: 'deleteTimezones',
  getTimezoneList: 'getTimezoneList',
  getCurrenciesList: 'getCurrenciesList',
  currenciesListing: 'currenciesListing',
  deleteCurrencies: 'deleteCurrencies',
  changeCurrenciesStatus: 'changeCurrenciesStatus',
  //social media sdk
  getsocialMediaSDK: 'getSocialMediaSDK',
  addSocialMediaSDK: 'addSocialMediaSDK',
  //social media link
  getSocialMediaLinks: 'getSocialMediaLinks',
  addSocialMediaLinks: 'addSocialMediaLinks',
  //global setiing
  addGlobalSettings: 'addGlobalSettings',
  getGlobalSettings: 'getGlobalSettings',
  //payment setting
  getPaymentDetails: 'getPaymentDetails',
  addPaymentDetails: '/addPaymentDetails',
  //smtp setting
  addSMTPAndSMSSettings: 'addSMTPAndSMSSettings',
  getSMTPAndSMSSettings: 'getSMTPAndSMSSettings',
  //email notification
  getEmailNotificationSettings: 'getEmailNotificationSettings',
  addEmailNotificationSettings: 'addEmailNotificationSettings',

  // email-template
  EmailTemplateList: 'listEmail',
  EmailTemplateDetail: 'detailEmail/',
  EmailColumnData: 'getEmailColumnValues',
  // EmailColumnVisibleSettings: 'emailColumnSettings',
  DeleteEmailTemplate: 'deleteTemplate',
  EmailAddNewEmail: 'addUpdateEmail',
  EmailChangeStatus: 'changeTemplateStatus',
  SearchEmail: 'searchEmail',

  //Faq
  FaqsListing: 'faqsListing',
  DeleteFaqs: 'deleteFAQs',
  FaqsDetails: 'getFAQDetails/',
  FaqsAddorUpdate: 'addUpdateFAQ',
  FaqsCategoryListing: 'faqCategoriesListing',
  FaqsStatusChange: 'changeFAQsStatus',
  FaqsSearchList: 'getFAQsList',
  AddUpdateFaqsCategory: 'addUpdateFAQCategory',
  DeleteFaqsCategory: 'deleteFAQCategories',
  FaqsCategoryDetails: 'getFAQCategoryDetails/',
  FaqsCategoryStatus: 'changeFAQCategoriesStatus',
  FaqsCategoriesSearchList: 'getFAQCategoriesList',

  // static && Blogs
  Listing: 'listBlog',
  DeleteBlog: 'deleteBlog',
  ChangeStatus: 'changeBlogStatus',
  GetBlogDetails: 'getBlogDetails/',
  AddBlog: 'addUpdateBlog',
  BlogCategoryListing: 'listBlogCategory',

  AddUpdateBlogCategory: 'addUpdateblogCategory',
  GetBlogCategoryDetails: 'getBlogCategoryDetails/',
  BlogCategoryStatusChange: 'changeBlogCategoryStatus',
  DeleteBlogCategory: 'deleteBlogCategory',
  // user
  UserList: 'user/userListing',
  UserFileUpload: 'users/fileUpload',
  UserDeleteUsers: 'user/deleteUsers',
  UserChangeStatus: 'user/changeStatus',
  GetUserDetails: 'user/userProfile/',
  UserCSVforDataTable: 'user/downloadCsv',
  UserEXCELforDataTable: 'user/downloadExcel',
  UserColumnVisibleSettings: 'user/columnSettings',
  CommonColumnVisibleSettings: 'saveColumnSettings',
  SaveDownloadTemplate: 'saveTemplateSettings',
  getDownloadTemplate: 'getTemplateSettings',
  deleteDownloadTemplate: 'deleteTemplateSettings/',
  downloadUserFile: 'downloadUserFile',
  UserGetColumnData: 'user/getColumnValues',
  UserGetFilters: 'user/getFilters',
  SearchUser: 'user/search',
  incidentUser: 'incidentManagement/userListingBySearch',

  AddAdminUser: 'admin/addAdminUser',
  UpdateAdminUser: 'admin/updateUser',
  AdminList: 'admin/userListing',
  AdminDeleteUsers: 'admin/deleteUsers',
  AdminChangeStatus: 'admin/changeStatus',
  GetAdminDetails: 'admin/userProfile/',
  downloadAdminUserFile: 'downloadAdminFile',
  AdminGetColumnData: 'admin/getColumnValues',
  AdminColumnVisibleSettings: 'admin/columnSettings',
  AdminGetFilters: 'admin/getFilters',
  AdminSaveFilters: 'admin/saveFilter',
  AdminSearch: 'admin/search',
  AdminExcel: 'admin/downloadExcel',
  AdminCsv: 'admin/downloadCsv',
  //add user
  AddUsers: 'user/userProfile/createUser',
  EditUsers: 'user/userProfile/editUser',
  InviteUsers: 'user/userProfile/inviteUser',
  EditInviteUsers: 'user/userProfile/editInvitedUser',
  CmsUplaod: 'cms/fileUpload',

  //Tag
  TagGetList: 'tags/listTags',
  AddTags: 'tags/createTag',
  DeleteTag: 'tags/deleteTag',
  StatusTag: 'tags/changeStatus',
  EditTag: 'tags/editTag',
  TagCsv: 'tags/downloadCsv',
  TagExcel: 'tags/downloadExcel',
  TagSearch: '/tags/search',
  GetTags: 'tags/getDetails',
  AddTagsCsv: 'tags/addTagsCsv',

  //Expertise
  ExpertiseList: 'expertise/expertiseListing',
  StatusExpertise: 'expertise/changeStatus',
  DeleteExpertise: 'expertise/deleteExpertise',
  AddExpertise: 'expertise/addExpertise',
  EditExpertise: 'expertise/editExpertise',
  ExpertiseSearch: 'expertise/search',

  //MechanicalFailure
  MechanicalFailureList: 'mechanicalFailure/mechanicalFailureListing',
  StatusMechanicalFailure: '/mechanicalFailure/changeStatus',
  DeleteMechanicalFailure: 'mechanicalFailure/deleteMechanicalFailures',
  AddMechanicalFailure: 'mechanicalFailure/add',
  EditMechanicalFailure: 'mechanicalFailure/editMechanicalFailure',
  MechanicalFailureseSearch: '/mechanicalFailure/search',
  MechanicalFailureCsv: 'mechanicalFailure/downloadCsv',
  MechanicalFailureExcel: 'mechanicalFailure/downloadExcel',

  //Department
  DepartmentList: 'department/departmentListing',
  StatusDepart: 'department/changeStatus',
  DeleteDepart: 'department/deleteDepartments',
  AddDepartment: 'department/addDepartment',
  EditDepartment: 'department/editDepartment',
  DepartmentCsv: 'department/downloadCsv',
  DepartmentExcel: 'department/downloadExcel',
  DepartmentSearch: 'department/search',

  //blogs
  BlogsList: 'blogs/blogsListing',
  StatusBlogs: 'blogs/changeStatus',
  DeleteBlogs: 'blogs/deleteBlogs',
  AddBlogs: 'blogs/addBlogs',
  EditBlogs: 'blogs/editBlogs',
  BlogsCsv: 'blogs/downloadCsv',
  BlogsExcel: 'blogs/downloadExcel',
  BlogsSearch: 'blogs/search',
  BlogsFilesUpload: 'blogs/fileUpload',

  //news
  NewsList: 'news/newsListing',
  StatusNews: 'news/changeStatus',
  DeleteNews: 'news/deleteNews',
  AddNews: 'news/addNews',
  EditNews: 'news/editNews',
  NewsCsv: 'news/downloadCsv',
  NewsExcel: 'news/downloadExcel',
  NewsSearch: 'news/search',
  //Region
  RegionList: 'region/regionListing',
  Addregion: 'region/addRegion',
  Editregion: 'region/editRegionById',
  StatusRegion: 'region/changeStatus',
  DeleteRegion: 'region/deleteRegions',
  ExcelRegion: 'region/downloadExcel',
  CsvRegion: 'region/downloadCsv',
  SearchRegion: 'region/search',

  //Training
  TrainingList: 'training/trainingListing',
  AddTraining: 'training/addTraining',
  EditTraining: 'training/editTrainingById',
  StatusTraining: 'training/changeStatus',
  DeleteTraining: 'training/deleteTraining',
  ExcelTraining: 'training/downloadExcel',
  CsvTraining: 'training/downloadCsv',
  SearchTraining: 'training/search',
  //designation
  DesignationList: 'designation/designationListing',
  StatusDesignation: 'designation/changeStatus',
  DeleteDesignation: 'designation/deleteDesignation',
  AddDesignation: 'designation/addDesignation',
  EditDesignation: 'designation/editDesignation',
  DesignationCsv: 'designation/downloadCsv',
  DesignationExcel: 'designation/downloadExcel',
  DesignationSearch: 'designation/search',

  //Postition
  PositionList: 'positions/positionListing',
  StatusPosition: 'positions/changeStatus',
  DeletePosition: 'positions/deletePosition',
  AddPosition: 'positions/addPosition',
  EditPosition: 'positions/editPosition',
  PositionCsv: 'positions/downloadCsv',
  PositionExcel: 'positions/downloadExcel',
  PositionSearch: 'positions/search',

  //healthcare Roles
  HealthcareRoleList: 'healthcareroles/healthcareRoleListing',
  StatusHealthcareRole: 'healthcareroles/changeStatus',
  DeleteHealthcareRole: 'healthcareroles/deleteHealthcareRole',
  AddHealthcareRole: 'healthcareroles/addHealthcareRole',
  EditHealthcareRole: 'healthcareroles/editHealthcareRole',
  HealthcareRoleCsv: 'healthcareroles/downloadCsv',
  HealthcareRoleExcel: 'healthcareroles/downloadExcel',
  HealthcareRoleSearch: 'healthcareroles/search',
  HealthcareRoleGetMetaData: 'healthcareroles/getMetaData',

  //Airline
  AirlineList: 'airline/airlineListing',
  AddAirline: 'airline/addAirline',
  EditAirline: 'airline/editAirline',
  StatusAirline: 'airline/changeStatus',
  DeleteAirline: 'airline/deleteAirline',
  SearchAirline: 'airline/search',

  //Place of Service
  ServiceList: 'placeOfService/serviceListing',
  AddService: 'placeOfService/add',
  EditService: 'placeOfService/editService',
  StatusService: 'placeOfService/changeStatus',
  DeleteService: 'placeOfService/deleteServices',
  SearchService: 'placeOfService/search',

  //sub-Region
  SubRegionList: 'subRegion/subRegionListing',
  AddSubRegion: 'subRegion/addSubRegion',
  EditSubRegion: 'subRegion/editSubRegionById',
  StatusSubRegion: 'SubRegion/changeStatus',
  DeleteSubRegion: 'SubRegion/deleteSubRegions',
  GetSubRegionById: 'subRegion/getSubRegionById',
  SubRegionCsvDownload: 'subRegion/downloadCsv',
  SubRegionExcelDownload: 'subRegion/downloadExcel',
  SearchSubRegion: 'subRegion/search',
  GetColumnSubRegion: 'subregion/getColumnValues',
  GetColumnRegion: 'region/getColumnValues',
  //Domain
  DomainList: 'domain/domainListing',
  StatusDomain: 'domain/changeStatus',
  EditDomain: 'domain/editDomainById/',
  EditAviationDomain: 'domain/editAviationDomainById/',
  EditNeutralDomain: 'domain/editNeutralDomainById/',
  DeleteDomain: 'domain/deleteDomain',
  GetDomainById: 'domain/getDomainById/',
  Detailsdomain: 'domain/getDetails',
  DetailDomain: 'domain/getDetails',
  AddDomain: 'domain/addDomain',
  AddAviationDomain: 'domain/addAviationDomain',
  AddNeutralDomain: 'domain/addNeutralDomain',
  DomainCsv: 'domain/downloadCsv',
  DomainExcel: 'domain/downloadExcel',
  DomainSearch: 'domain/search',
  GetDomainColumnSubRegion: 'subRegion/getColumnValues',
  GetDomainColumnRegion: 'region/getColumnValues',

  //translation
  GetTranslationByID: 'translation/getTranslationsById',
  TranslationChangeStatus: 'translation/changeStatus',
  AddTranslation: 'translation/addTranslation',
  EditTranslation: 'translation/editTranslation',
  DeleteTranslation: 'translation/deleteTranslation',

  //solution translations
  GetSolutionTranslationByID: 'translation/getSolutionTranslationsById',
  AddSolutionTranslation: 'translation/addSolutionTranslation',

  //news translations
  GetNewsTranslationByID: 'translation/getNewsTranslationsById',
  AddNewsTranslation: 'translation/addNewsTranslation',

  //blog translations
  GetBlogTranslationByID: '/translation/getBlogTranslationsById',
  AddBlogTranslation: '/translation/addBlogTranslation',

  //Tag translations
  GetTagTranslationsById: 'translation/getTagTranslationsById',
  AddTagTranslation: 'translation/addTagTranslation',

  GetInfographicTranslationByID: 'translation/getInfographicTranslationsById',
  AddInfographicTranslation: 'translation/addInfographicTranslation',

  //Incident
  IncidentList: 'incidentManagement/incidentListing',
  GetIncidentByID: 'incidentManagement/getIncidentById',
  getIncidentData: 'incidentManagement/getMetaData',
  getIncidentMetaData: 'incident/getMetaData',
  IncidentChangeStatus: 'incidentManagement/changeStatus',
  DeleteIncident: 'incidentManagement/deleteIncidents',
  IncidentFileUpload: 'incidentManagement/fileUpload',
  DeleteFileUpload: 'incidentManagement/deleteUploadedFile',
  AddIncident: 'incidentManagement/postIncident',
  EditIncident: 'incidentManagement/editIncident',
  RejectChangeStatus: 'incidentManagement/rejectChangeStatus',
  GetColumnIncidentTitle: 'incidentManagement/getColumnValues',
  GetColumnIncidentRegion: 'region/getColumnValues',
  GetColumnIncidentDepartment: 'department/getColumnValues',
  GetColumnIncidentAuthor: 'user/getColumnValues',
  IncidentCsv: 'incidentManagement/downloadCsv',
  IncidentExcel: 'incidentManagement/downloadExcel',
  IncidentSearch: 'incidentManagement/search',
  //report

  //Solution
  SolutionList: 'solutionManagement/solutionListing',
  DeleteSolution: 'solutionManagement/deleteSolution',
  SolutionChangeStatus: 'solutionManagement/changeStatus',
  GetSolutionData: 'solutionManagement/getMetaData',
  AddSolution: 'solutionManagement/postSolution',
  GetSolutionByID: 'solutionManagement/getSolutionById',
  EditSolution: 'solutionManagement/editSolution',
  SolutionCsv: 'solutionManagement/downloadCsv',
  SolutionExcel: 'solutionManagement/downloadExcel',
  SolutionSearch: 'solutionManagement/search',
  GetColumnSolutionTitle: 'solutionManagement/getColumnValues',
  GetColumnSolutionParent: 'incidentManagement/getColumnValues',
  GetColumnSolutionAuthor: 'user/getColumnValues',
  DeleteListSolution: 'solutionManagement/deleteRequestedSolutionListing',
  DeleteReportStatusSolution: 'solutionManagement/deleteRequestedSolution',
  DeleteReportRestoreSolution: 'solutionManagement/restoreRequestedSolution',

  //Role
  RoleList: 'listRole',
  GetRoleDetail: 'getRolePermission',
  AddUpdateRole: 'addRole',
  RoleChangeStatus: 'changeRoleStatus',
  RoleGetColumnData: 'roleFieldsList',
  RoleGetaddedByColumnData: 'adminListInRoles',
  Role_Category: '/getCategory',
  Role_Permission: '/getAllPermission',
  // common
  deleteFilter: 'deleteFilter/',
  saveFilter: 'saveFilter',
  //Report
  ReportList: 'reportedContent/reportedContenListing',
  ReportChangeStatus: 'reportedContent/changeStatus',
  DeleteReportcontent: 'reportedContent/deleteReportedContent',
  DeleteReportList: 'incidentManagement/deleteRequestedIncidentListing',
  DeleteReportStatus: 'incidentManagement/deleteRequestedIncident',
  DeleteReportRestore: 'incidentManagement/restoreRequestedIncident',
  GetColumnReportsolutionTitle: 'solutionManagement/getColumnValues',
  GetColumnReportsolutionAuthor: 'user/getColumnValues',
  GetColumnReportIncidentTitle: 'incidentManagement/getColumnValues',
  GetColumnReportIncidentDepartment: 'department/getColumnValues',
  GetColumnReportIncidentAuthor: 'user/getColumnValues',
  GetColumnReportIncidentRegion: 'region/getColumnValues',
  ReportCsvDownload: 'reportedContent/downloadCsv',
  ReportExcelDownload: 'reportedContent/downloadExcel', //reportedContent​/downloadCsv
  //cms /region/getColumnValues
  contentList: 'cmsList',
  DeleteContent: 'cmsDelete',
  AddUpdateCms: 'addUpdateCMS',
  CmsDetails: 'cmsDetail/',
  ContentSearch: 'searchCms',
  //Notification
  GetNotificationCount: 'adminGetNotificationCount',
  NotificationList: 'adminNotificationListing',
  ReadNotification: 'adminReadNotifications',
  //DASHBORDS
  ViewDashboard: 'admin/viewDashboard',
  DashboardMeta: 'admin/dashboardMetaData',
  DashboardCharts: 'admin/dashboardCharts',
  //admin/dashboardCharts
  Recommended_list: 'recommendedDomain/listing',
  AddRecommended: 'recommendedDomain/addDomain',
  AddRecommendedAviation: 'recommendedDomain/addAviationDomain',
  AddRecommendedNeutral: 'recommendedDomain/addNeutralDomain',
  StatusRecommended: 'recommendedDomain/approveRejectDomain',
  DownloadCSvRecommended: 'recommendedDomain/downloadCsv',
  DownloadExcelRecommended: '/recommendedDomain/downloadExcel',
  SearchRecommended: 'recommendedDomain/search',
  EditRecommended: 'recommendedDomain/editDomainById/',
  EditRecommendedAviation: 'recommendedDomain/editAviationDomainById/',
  EditRecommendedNeutral: 'recommendedDomain/editNeutralDomainById/',
  DownLoadExcelIncident: 'incidentManagement/downloadIncidentStats',

  //REACHUS
  Reachlist: 'reachus/reachUsListing',
  DeleteReachUs: 'reachus/deleteReachUs',
  ChangeReachUsStatus: 'reachus/changeStatus',
  //admin post
  PostList: 'postManagement/postListing',
  AddPost: 'postManagement/addPost',
  EditPost: 'postManagement/editPost',
  DeletePost: 'postManagement/deletePost',
  PostFileUpload: 'postManagement/fileUpload',
  PostDeleteFileUpload: 'postManagement/deleteUploadedFile',
  GetPostMetaData: 'postManagement/getMetaData',
  GetSolutionsForPost: '/postManagement/solutionListing',
  GetCommentsForPost: '/postManagement/commentListing',
  AddAdminComment: '/postManagement/addComment',
  AddAdminSolution: '/postManagement/addSolution',

  //infographics
  InfographicList: 'infographicManagement/graphicListing',
  InfographicFileUpload: 'infographicManagement/fileUpload',
  AddGeneratedInfographic: 'infographicManagement/addGeneratedGraph',
  AddImageInfographic: 'infographicManagement/addGraphImage',
  EditGeneratedInfographic: 'infographicManagement/editGeneratedGraph',
  EditImageInfographic: 'infographicManagement/editGraphImage',
  GetInfographicById: 'infographicManagement/getGraphicById',
  InfographicDeleteUploadedFile: 'infographicManagement/deleteUploadedFile',
  DeleteInfographic: 'infographicManagement/delete',

  //pre approved email tags
  EmailTagList: 'preApprovedEmail/emailTagList',
  AddEmailTag: 'preApprovedEmail/addEmailTag',
  EmailTagsSearch: 'preApprovedEmailTag/search',
  EmailTagsChangeStatus: 'preApprovedEmail/changeTagStatus',
  EmailTagDelete: 'preApprovedEmail/deleteEmailTag',
  GetEmailTagById: 'preApprovedEmail/getEmailTagById/',
  EditEmailTagById: '/preApprovedEmail/editEmailTagById/',
  TagDetails: '/preApprovedEmailTag/getRegionSubRegion',

  //pre approved emails
  EmailList: 'preApprovedEmail/emailList',
  AddEmail: 'preApprovedEmail/addEmail',
  AddEmailsCsv: 'preApprovedEmail/addEmailCsv',
  EmailSearch: 'preApprovedEmail/searchEmail',
  EmailStatus: 'preApprovedEmail/changeEmailStatus',
  EmailDelete: 'preApprovedEmail/deleteEmail',
  GetEmailById: 'preApprovedEmail/getEmailById/',
  EditEmailById: 'preApprovedEmail/editEmailById/',
  GetEmailTags: 'preApprovedEmailTag/getDetails',

  //comments
  CommentList: 'commentManagement/commentList',
  DeleteComment: 'commentManagement/deleteComment',
  SearchComment: 'commentManagement/search',

  //QR
  QrList: 'qrCampaignManagement/qrCampaignListing',
  DeleteQr: 'qrCampaignManagement/deleteQrCampaign',
  AddQr: 'qrCampaignManagement/postQrCampaign',
  ViewQrCampaign: 'qrCampaignManagement/getQrCampaignById',

  //replies
  replyList: 'replyManagement/replyList',
  DeleteReply: 'replyManagement/deleteReply',
  SearchReply: 'replyManagement/search',

  airportSearch: 'airport/search',
};
