import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

// plugin
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CountdownModule } from 'ngx-countdown';
import { CommonModule } from '@angular/common';
import { ResponsiveDataTableComponent } from './components/responsive-data-table/responsive-data-table.component';
import { CommonModalComponent } from './components/modals/common-modal/common-modal.component';
import { TemplateDetailsComponent } from './components/modals/template-details/template-details.component';
import { BnDatatableModule } from '../common/service/bn-datatable/bn-datatable.module';
import { DataTablesModule } from 'angular-datatables';
// Import library module
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
// service

// Component
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddblogComponent } from './components/modals/addblog/addblog.component';
import { FaqsListCategoryComponent } from './components/modals/faqs-list-category/faqs-list-category.component';
import { FaqsCategoryComponent } from './components/modals/faqs-category/faqs-category.component';
import { AddTagComponent } from './components/modals/add-tag/add-tag.component';
import { AddDepartmentComponent } from './components/modals/add-department/add-department.component';
import { AddRegionComponent } from './components/modals/add-region/add-region.component';
import { AddDomainComponent } from './components/modals/add-domain/add-domain.component';
import { AddsubRegionComponent } from './components/modals/addsub-region/addsub-region.component';
import { ReportComponent } from './components/modals/report/report.component';
import { RemoveReportComponent } from './components/modals/remove-report/remove-report.component';
import { TextareaAutoresizeDirective } from '../common/service/textarea-autoresize.directive';
import { FilterTypeAheadComponent } from './components/filter-type-ahead/filter-type-ahead.component';
import { ToastrModule } from 'ngx-toastr';
import { ReportSolutionComponent } from './components/modals/report-solution/report-solution.component';
import { AddRecommandedComponent } from './components/modals/add-recommanded/add-recommanded.component';
import { EditIncidentModalComponent } from './components/modals/edit-incident-modal/edit-incident-modal.component';
import { AddEmailTagComponent } from './components/modals/add-email-tag/add-email-tag.component';
import { AddEmailComponent } from './components/modals/add-email/add-email.component';
import { DeleteCommentComponent } from './components/modals/delete-comment/delete-comment.component';
import { AddQrComponent } from './components/modals/add-qr/add-qr.component';
import { AddCommentComponent } from './components/modals/add-comment/add-comment.component';
import { AddSolutionComponent } from './components/modals/add-solution/add-solution.component';
import { AddExpertiseComponent } from './components/modals/add-expertise/add-expertise.component';
import { AddTrainingComponent } from './components/modals/add-training/add-training.component';
import { AddAirlineComponent } from './components/modals/add-airline/add-airline.component';
import { AddServiceComponent } from './components/modals/add-place-of-service/add-service.component';
import { AddmechanicalFailureComponent } from './components/modals/addmechanical-failure/addmechanical-failure.component';
import { AddDesignationComponent } from './components/modals/add-designation/add-designation.component';
import { AddHealthcareRoleComponent } from './components/modals/add-healthcarerole/add-healthcarerole.component';
import { AddPositionComponent } from './components/modals/add-position/add-position.component';
import { AddTranslationComponent } from './components/modals/add-translation/add-translation.component';
import { AddSolutionTranslationComponent } from './components/modals/add-solution-translation/add-solution-translation.component';
import { AddNewsComponent } from './components/modals/add-news/add-news.component';
import { AddNewsTranslationComponent } from './components/modals/add-news-translation/add-news-translation.component';
import { AddInfographicTranslationComponent } from './components/modals/add-infographic-translation/add-infographic-translation.component';
import { AddTagsTranslationComponent } from './components/modals/add-tags-translation/add-tags-translation.component';
import { AddBlogsComponent } from './components/modals/add-blogs/add-blogs.component';
import { AddBlogsTranslationComponent } from './components/modals/add-blog-translation/add-blogs-translation.component';

// import { HighchartsChartModule } from 'highcharts-angular';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    AngularEditorModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    DragDropModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    BnDatatableModule,
    DataTablesModule,
    CountdownModule,
    TabsModule.forRoot(),
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxSpinnerModule,

    // HighchartsChartModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ResponsiveDataTableComponent,
    CommonModalComponent,
    TemplateDetailsComponent,
    AddblogComponent,
    FaqsListCategoryComponent,
    FaqsCategoryComponent,
    AddTagComponent,
    AddDepartmentComponent,
    AddDesignationComponent,
    AddHealthcareRoleComponent,
    AddPositionComponent,
    AddRegionComponent,
    AddDomainComponent,
    AddsubRegionComponent,
    ReportComponent,
    RemoveReportComponent,
    TextareaAutoresizeDirective,
    FilterTypeAheadComponent,
    ReportSolutionComponent,
    AddRecommandedComponent,
    EditIncidentModalComponent,
    AddEmailTagComponent,
    AddEmailComponent,
    DeleteCommentComponent,
    AddQrComponent,
    AddCommentComponent,
    AddExpertiseComponent,
    AddTrainingComponent,
    AddAirlineComponent,
    AddSolutionComponent,
    AddServiceComponent,
    AddmechanicalFailureComponent,
    AddTranslationComponent,
    AddSolutionTranslationComponent,
    AddNewsComponent,
    AddBlogsComponent,
    AddNewsTranslationComponent,
    AddInfographicTranslationComponent,
    AddTagsTranslationComponent,
    AddBlogsTranslationComponent,
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    //components
    HeaderComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ResponsiveDataTableComponent,
    TemplateDetailsComponent,
    DragDropModule,

    //modules
    NgxSpinnerModule,
    FormsModule,
    CommonModule,
    BnDatatableModule,

    //plugin
    AngularEditorModule,
    ImageCropperModule,
    BsDatepickerModule,
    TabsModule,
    PaginationModule,
    NgSelectModule,
    TooltipModule,
    CountdownModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    // HighchartsChartModule,
    ToastrModule,
  ],
  entryComponents: [TemplateDetailsComponent, CommonModalComponent],
})
export class SharedModule {}
