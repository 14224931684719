import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/common/service/theme/theme.service';
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { ShowErrorService } from '../../common/service/show-error.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [],
})
export class ForgotPasswordComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  public errorMsg: string = '';
  public forgotPassword: any = {};
  public isHealthcareSelected: boolean = false;

  constructor(
    private themeService: ThemeService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private showErrorService: ShowErrorService
  ) {
    this.localStorageService.setToken('industry', 'aviation');
  }

  ngOnInit(): void {
    var isDark =
      this.localStorageService.getToken('theme') == 'Dark' ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document
        .getElementById('forgotPassSwitchTheme')
        .setAttribute('checked', '');
  }

  onForgotPassword(form) {
    if (form.value.email !== null && form.value.email !== '') {
      this.forgotPassword = {
        emailId: form.value.email,
      };
      this.commonService
        .callApi(
          this.callAPIConstants.ForgotPassword,
          this.forgotPassword,
          'post',
          true,
          false
        )
        .then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast('success', success.message, 5000);
            this.router.navigate([this.URLConstants.LOGIN]);
          } else {
            this.showErrorService.popToast('error', success.message, 5000);
          }
        });
    }
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }

  industrySelect(event) {
    let industry = event.target.id;
    this.localStorageService.setToken('industry', industry);

    this.isHealthcareSelected = !this.isHealthcareSelected;
  }
}
