import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ThemeService } from '../../common/service/theme/theme.service';
import { ShowErrorService } from '../../common/service/show-error.service';

// environment

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [],
})
export class LoginComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  passwordType: boolean;
  public user: any = {};
  uploadImageUrl = environment.uploadImgUrl;
  public remainingAttempts = null;
  public isHealthcareSelected: boolean = false;

  constructor(
    private themeService: ThemeService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private showErrorService: ShowErrorService,
    public route: ActivatedRoute
  ) {
    this.localStorageService.setToken('industry', 'aviation');
  }

  ngOnInit(): void {
    var isDark =
      this.localStorageService.getToken('theme') == 'Dark' ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document.getElementById('loginSwitchTheme').setAttribute('checked', '');
  }

  onLogin(form) {
    var numbers = /^[0-9]+$/;
    var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      form.value.emailOrPhone.match(email) ||
      (form.value.emailOrPhone.match(numbers) &&
        form.value.emailOrPhone.length == 10)
    ) {
      this.user = {
        emailId: form.value.emailOrPhone,
        password: form.value.password,
      };
      this.commonService
        .callApi(
          this.callAPIConstants.AdminLogin,
          this.user,
          'post',
          true,
          false
        )
        .then((success) => {
          if (success.status === 1 && !success.data) {
            this.showErrorService.popToast('error', success.message);
            return;
          } else if (success.status === 1 && success.data) {
            this.localStorageService.setToken(
              'accessToken',
              success.access_token
            );
            this.localStorageService.setToken(
              'firstname',
              success.data.firstname
            );
            this.localStorageService.setToken(
              'lastname',
              success.data.lastname
            );
            this.localStorageService.setToken('userId', success.data._id);
            if (success.data.photo != undefined) {
              this.localStorageService.setToken('photo', success.data.photo);
            }
            this.localStorageService.setToken('role', success.data.role.role);
            this.localStorageService.setToken('theme', success.data.theme);
            this.localStorageService.setToken(
              'menuAlignment',
              success.data.menuAlignment
            );
            this.localStorageService.setToken(
              'role_permission',
              this.commonService.encrypt(success.data.rolePermission)
            );
            var isDark = success.data.theme == 'Dark' ? true : false;
            this.themeService.switchThemeAfterLogin(isDark);
            this.showErrorService.popToast('success', success.message);
            let params = this.route.snapshot.queryParams;

            if (params['redirectURL']) {
              const redirectURL = params['redirectURL'];
              this.router
                .navigateByUrl(redirectURL)
                .catch(() => this.router.navigate([URLConstants.DASHBOARD]));
            } else {
              this.router.navigate([URLConstants.DASHBOARD]);
            }
            // this.router.navigate([this.URLConstants.DASHBOARD]);
          } else {
            success.remainingAttempts &&
              (this.remainingAttempts =
                success.remainingAttempts > 1
                  ? `${success.remainingAttempts} attempts left`
                  : `${success.remainingAttempts} attempt left`);

            success.remainingAttempts === 0 &&
              (this.remainingAttempts = 'Account deactivated');
            this.showErrorService.popToast('error', success.message);
          }
        });
    }
  }
  /*************************************************************/
  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }

  industrySelect(event) {
    let industry = event.target.id;
    this.localStorageService.setToken('industry', industry);

    this.isHealthcareSelected = !this.isHealthcareSelected;
  }
}
