<div class="main-content-area">
  <header id="header" class="">
    <div class="navigation-bar">
      <nav class="navbar d-flex navbar-expand bd-navbar fixed-top">
        <div
          class="mr-2 float-left d-flex align-items-center"
          (click)="openSidebar(); one.myFunctionOne()"
        >
          <ul class="nav navbar-nav">
            <li class="nav-item mobile-menu mr-auto">
              <a class="nav-link nav-menu-main menu-toggle hidden-xs"
                ><em class="bx bx-menu"></em
              ></a>
            </li>
          </ul>
          <ul class="horizontal-brand nav navbar-nav">
            <li>
              <a href="">
                <img
                  class="img-fluid"
                  src="assets/images/App-Icon.jpg"
                  alt="branding logo"
                />
              </a>
            </li>
          </ul>
        </div>
        <app-sidebar #one></app-sidebar>
        <ul class="navbar-nav flex-row ml-auto d-md-flex">
          <li class="nav-item dropdown dropdown-language">
            <a
              class="dropdown-toggle nav-link mr-md-2"
              id="dropdown-flag"
              href="#"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              ><i class="flag-icon flag-icon-us"></i>
              <!-- <span
                            class="selected-language">English</span> -->
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdown-flag"
            >
              <a class="dropdown-item" href="#" data-language="en"
                ><i class="flag-icon flag-icon-us mr-50"></i> English</a
              >
              <a class="dropdown-item" href="#" data-language="fr"
                ><i class="flag-icon flag-icon-fr mr-50"></i> French</a
              >
              <a class="dropdown-item" href="#" data-language="de"
                ><i class="flag-icon flag-icon-de mr-50"></i> German</a
              >
              <a class="dropdown-item" href="#" data-language="pt"
                ><i class="flag-icon flag-icon-pt mr-50"></i> Portuguese</a
              >
            </div>
          </li>
          <li class="nav-item nav-search">
            <div class="search-input">
              <div class="search-box">
                <div class="search-input-icon">
                  <i class="bx bx-search primary"></i>
                </div>
                <input
                  class="input"
                  type="text"
                  placeholder="Explore Search..."
                  tabindex="-1"
                  data-search="template-search"
                />
                <div class="search-input-close"><i class="bx bx-x"></i></div>
              </div>
              <ul class="search-list">
                <li
                  class="auto-suggestion align-items-center justify-content-between cursor-pointer current_item"
                >
                  <a class="align-items-center justify-content-between w-100">
                    <div class="justify-content-start">
                      <span class="mr-75 bx bx-error-circle"></span>
                      <span>No results found.</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item dropdown dropdown-notification" dropdown>
            <a
              class="nav-link nav-link-label"
              dropdownToggle
              type="button"
              id="notificationDropdown"
              aria-controls="dropdown-animated"
            >
              <i class="bx bx-bell bx-tada bx-flip-horizontal"></i>
              <span class="badge badge-pill badge-danger badge-up">{{
                notificationCount ? notificationCount : 0
              }}</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-media dropdown-menu-right"
              id="notificationDropdown"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-animated"
            >
              <div class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between">
                  <span class="notification-title"
                    >{{ notificationCount ? notificationCount : 'No' }} New
                    Notification's</span
                  >
                  <!-- <span class="text-bold-400 cursor-pointer">Mark all as read</span> -->
                </div>
              </div>

              <div class="notification-list ScrollStyle">
                <div
                  class="nav-item dropdown"
                  [ngClass]="{
                    active: !item.isRead
                  }"
                  *ngFor="let item of datalist"
                >
                  <div
                    *ngIf="!item.isRead"
                    class="row"
                    (click)="changeNotification($event.target.value, item)"
                  >
                    <div class="col-md">
                      <p class="title">{{ item?.title | titlecase }}</p>
                      <p class="desc">{{ item?.notificationMessage }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="nav-item user-dropdown dropdown" dropdown>
            <a
              class="nav-link dropdown-toggle dropdown-user-link"
              dropdownToggle
              type="button"
              id="userDropdown"
              aria-controls="dropdown-animated"
            >
              <div class="user-nav d-sm-flex d-none">
                <span class="user-name">{{ firstname }} {{ lastname }}</span>
              </div>
              <span class="profile-header">
                <img
                  class=""
                  [src]="photo ? photo : 'assets/images/NoProfile.png'"
                  alt="no img"
              /></span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              id="userDropdown"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-animated"
            >
              <a
                *ngIf="router.url !== URLConstants.MY_PROFILE"
                class="dropdown-item"
                [routerLink]="[URLConstants.MY_PROFILE]"
                ><i class="bx bx-user mr-2"></i
                >{{ 'Header.PROFILE' | translate }}</a
              >
              <a
                style="cursor: pointer"
                (click)="openModal(template)"
                class="dropdown-item"
                ><i class="bx bx-user mr-2"></i
                >{{ 'Header.CHANGEPASSWORD' | translate }}</a
              >

              <a
                class="dropdown-item"
                style="cursor: pointer"
                (click)="logout()"
                ><i class="bx bx-log-out mr-2"></i
                >{{ 'Header.LOGOUT' | translate }}</a
              >
            </div>
            <ng-template #template>
              <div class="modal-header">
                <h4 class="modal-title pull-left">Change Password</h4>
                <button
                  type="button"
                  class="close pull-right"
                  aria-label="Close"
                  (click)="modalRef.hide()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form
                  (ngSubmit)="
                    changePassForm.form.valid && changePassword(changePassForm)
                  "
                  #changePassForm="ngForm"
                >
                  <div class="form-group mb-2 position-relative">
                    <label
                      >Old Password<sup class="bx bxs-star danger"></sup
                    ></label>
                    <input
                      [type]="passwordTypeOld ? 'text' : 'password'"
                      ngModel
                      id="password-field"
                      type="password"
                      name="oldPassword"
                      class="form-control"
                      #oldPassword="ngModel"
                      placeholder="Old password"
                      [ngClass]="{
                        'is-invalid':
                          (changePassForm.submitted ||
                            changePassForm.touched) &&
                          oldPassword.invalid
                      }"
                      required
                      pattern="^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$"
                    />
                    <span
                      [tooltip]="
                        passwordTypeOld ? 'Hide Password' : 'Show Password'
                      "
                      toggle="#password-field"
                      class="bx bx-hide field-icon toggle-password"
                      [ngClass]="{
                        'bx-bx-hide': !passwordTypeOld,
                        'bx-show': passwordTypeOld
                      }"
                      (click)="toggleFieldTextTypeOld()"
                    ></span>

                    <div
                      *ngIf="
                        (changePassForm.submitted || changePassForm.touched) &&
                        oldPassword.invalid
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="oldPassword.errors.required">
                        Please enter old password
                      </div>
                      <div *ngIf="oldPassword.errors.pattern">
                        Password should Only Alphanumeric and minimum 7
                        character long
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-2 position-relative">
                    <label
                      >New Password<sup class="bx bxs-star danger"></sup
                    ></label>
                    <input
                      [type]="passwordTypeNew ? 'text' : 'password'"
                      ngModel
                      id="password-field"
                      type="password"
                      name="newPassword"
                      class="form-control"
                      #newPassword="ngModel"
                      placeholder="New password"
                      [ngClass]="{
                        'is-invalid':
                          (changePassForm.submitted ||
                            changePassForm.touched) &&
                          newPassword.invalid
                      }"
                      minlength="6"
                      required
                      pattern="^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$"
                    />
                    <span
                      [tooltip]="
                        passwordTypeNew ? 'Hide Password' : 'Show Password'
                      "
                      toggle="#password-field"
                      class="bx bx-hide field-icon toggle-password"
                      [ngClass]="{
                        'bx-bx-hide': !passwordTypeNew,
                        'bx-show': passwordTypeNew
                      }"
                      (click)="toggleFieldTextTypeNew()"
                    ></span>

                    <div
                      *ngIf="
                        (changePassForm.submitted || changePassForm.touched) &&
                        newPassword.invalid
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="newPassword.errors.required">
                        Please enter new password
                      </div>
                      <div *ngIf="newPassword.errors.pattern">
                        Password should Only Alphanumeric and minimum 7
                        character long
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-2 position-relative">
                    <label
                      >Confirm Password<sup class="bx bxs-star danger"></sup
                    ></label>
                    <input
                      [type]="passwordType ? 'text' : 'password'"
                      ngModel
                      id="password-field"
                      type="password"
                      name="confirmPassword"
                      class="form-control"
                      #confirmPassword="ngModel"
                      placeholder="confirm password"
                      [ngClass]="{
                        'is-invalid':
                          (changePassForm.submitted ||
                            changePassForm.touched) &&
                          confirmPassword.invalid
                      }"
                      required
                      minlength="6"
                    />
                    <span
                      [tooltip]="
                        passwordType ? 'Hide Password' : 'Show Password'
                      "
                      toggle="#password-field"
                      class="bx bx-hide field-icon toggle-password"
                      [ngClass]="{
                        'bx-bx-hide': !passwordType,
                        'bx-show': passwordType
                      }"
                      (click)="toggleFieldTextType()"
                    ></span>

                    <div
                      *ngIf="
                        (changePassForm.submitted || changePassForm.touched) &&
                        confirmPassword.invalid
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="confirmPassword.errors.required">
                        Please enter password
                      </div>
                      <div *ngIf="confirmPassword.errors.minlength">
                        Please enter valid 6 digit password
                      </div>
                    </div>
                    <div
                      class="form-control-feedback error-block"
                      style="color: red"
                      *ngIf="showErr"
                    >
                      New password & Confirm password not matched
                    </div>
                  </div>

                  <button
                    class="btn btn-primary glow position-relative btn-block mt-3"
                  >
                    Change Password
                  </button>
                </form>
              </div>
            </ng-template>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</div>
