<div class="theme-setting-block" [ngClass]="{ open: isSettingOpen }">
  <a class="theme-setting-link" (click)="openSettingBox()">
    <i
      class="bx bx-cog bx-flip-horizontal"
      [ngClass]="{ 'bx-spin': !isSettingOpen }"
    ></i>
  </a>
  <div class="d-flex align-items-center">
    <span class="light-icon icon mr-1 d-block"
      ><em class="bx bx-sun"></em
    ></span>
    <div class="custom-control custom-switch theme-switch">
      <input
        type="checkbox"
        class="custom-control-input"
        id="loginSwitchTheme"
        (click)="toggleTheme()"
      />
      <label class="custom-control-label" for="loginSwitchTheme"></label>
    </div>
    <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
  </div>
</div>
<!--Start Login Wrapper-->
<div
  class="login-wrapper"
  style="
    background: url(assets/images/login-bg.jpg) no-repeat center center;
    background-size: cover;
  "
>
  <div class="login-body">
    <section class="login-container row m-0">
      <div class="col-xl-8 col-11">
        <div class="card bg-login-card">
          <div class="row m-0">
            <div class="col-md-6 col-12 pl-0 pr-0">
              <div
                class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100"
              >
                <div class="card-header">
                  <div class="card-title text-center mb-4">
                    <h4 class="mb-2">Welcome</h4>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <form
                      (ngSubmit)="loginForm.form.valid && onLogin(loginForm)"
                      (ngSubmit)="
                        loginForm.form.valid &&
                          loginForm.controls['captcha'].reset()
                      "
                      #loginForm="ngForm"
                    >
                      <div class="form-group mb-2">
                        <label
                          >UserName <sup class="bx bxs-star danger"></sup
                        ></label>
                        <input
                          pattern="^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$"
                          ngModel
                          type="text"
                          name="emailOrPhone"
                          id="EmailAddress"
                          aria-describedby="emailHelp"
                          placeholder="Enter email or phone"
                          class="form-control"
                          #emailOrPhone="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              (loginForm.submitted || loginForm.touched) &&
                              emailOrPhone.invalid
                          }"
                          required
                        />
                        <div
                          *ngIf="
                            (loginForm.submitted || loginForm.touched) &&
                            emailOrPhone.invalid
                          "
                          class="invalid-feedback"
                        >
                          <div *ngIf="emailOrPhone.errors.required">
                            Please enter email or phone
                          </div>
                          <div *ngIf="emailOrPhone.errors.pattern">
                            Please enter valid email or phone
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-group mb-2 position-relative password-validation"
                      >
                        <label
                          >Password<sup class="bx bxs-star danger"></sup
                        ></label>
                        <input
                          [type]="passwordType ? 'text' : 'password'"
                          ngModel
                          id="password-field"
                          type="password"
                          name="password"
                          class="form-control"
                          #password="ngModel"
                          placeholder="Enter password"
                          [ngClass]="{
                            'is-invalid':
                              (loginForm.submitted || loginForm.touched) &&
                              password.invalid
                          }"
                          required
                          pattern="^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$"
                        />
                        <span
                          [tooltip]="
                            passwordType ? 'Hide Password' : 'Show Password'
                          "
                          toggle="#password-field"
                          class="bx bx-hide field-icon toggle-password"
                          [ngClass]="{
                            'bx-bx-hide': !passwordType,
                            'bx-show': passwordType
                          }"
                          (click)="toggleFieldTextType()"
                        ></span>

                        <div
                          *ngIf="
                            (loginForm.submitted || loginForm.touched) &&
                            password.invalid
                          "
                          class="invalid-feedback"
                        >
                          <div *ngIf="password.errors.required">
                            Please enter password
                          </div>
                          <div *ngIf="password.errors.pattern">
                            Password should contain at least one capital letter
                            and a number and length should be between 8-20
                          </div>
                        </div>
                      </div>
                      <div class="row pt-4">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                          <div class="custom-control custom-radio mb-1">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="aviation"
                              name="industry"
                              [checked]="!isHealthcareSelected"
                              (change)="industrySelect($event)"
                            />
                            <label
                              class="custom-control-label"
                              for="aviation"
                              >{{ 'Aviation' }}</label
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="custom-control custom-radio mb-1">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="healthcare"
                              name="industry"
                              [checked]="isHealthcareSelected"
                              (change)="industrySelect($event)"
                            />
                            <label
                              class="custom-control-label"
                              for="healthcare"
                              >{{ 'Healthcare' }}</label
                            >
                          </div>
                        </div>
                        <div class="col-md-2"></div>
                      </div>
                      <div
                        class="form-group d-flex flex-md-row flex-column justify-content-between align-items-center mb-3"
                      >
                        <div class="text-right">
                          <a routerLink="/forgot-password" class="card-link">
                            Forgot password?
                          </a>
                        </div>
                      </div>

                      <div class="form-group mt-2 mb-4">
                        <re-captcha
                          siteKey="6Lc0G_cfAAAAANEXWwWETh4F7N-bLDXMTK1AnvfJ"
                          ngModel
                          name="captcha"
                          #captcha="ngModel"
                          required
                        ></re-captcha>
                      </div>

                      <button
                        class="btn btn-primary glow position-relative btn-block"
                      >
                        Login<i class="icon-arrow bx bx-right-arrow-alt"></i>
                      </button>
                    </form>
                    <p class="text-danger text-center mt-2">
                      {{ remainingAttempts }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 d-md-block d-none text-center align-self-center p-7"
            >
              <div class="card-content">
                <picture>
                  <source srcset="assets/images/login.webp" type="image/webp" />
                  <source srcset="assets/images/login.png" type="image/png" />
                  <img
                    class="img-fluid"
                    src="assets/images/login.png"
                    alt="login"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
