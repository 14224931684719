import { Component, OnInit } from '@angular/core';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [],
})
export class SidebarComponent implements OnInit {
  URLConstants = URLConstants;
  public subUserMenu: boolean = false;
  public subContentMenu: boolean = false;
  public subSettingsMenu: boolean = false;
  public subBlogMenu: boolean = false;
  public emailTemplate: boolean = false;
  public incidentMenu: boolean = false;
  public solutionMenu: boolean = false;
  public master: boolean = false;
  public roles: boolean = false;
  public dashboard: boolean = false;
  public postMenu: boolean = false;
  public emailMenu: boolean = false;
  subReportMenu: boolean = false;
  adminUserAccess: any;
  tagManagementAccess: any;
  departmentManagementAccess: any;
  designationManagementAccess: any;
  healthcareRoleManagementAccess: any;
  expertiseManagementAccess: any;
  positionManagementAccess: any;
  mechanicalFailureManagementAccess: any;
  airlineManagementAccess: any;
  placeOfServiceManagementAccess: any;
  reachUsManagementAccess: any;
  subRegionManagementAccess: any;
  userAccess: any;
  cmsPagesAccess: any;
  rolesAccess: any;
  regionManagementAccess: any;
  trainingManagementAccess: any;
  incidentManagementAccess: any;
  solutionManagementAccess: any;
  domainManagementAccess: any;
  requestedDeleteIncidentAccess: any;
  requestedDeleteSolutionAccess: any;
  recommendedDomainManagementAccess: any;
  postManagementAccess: any;
  preApprovedEmailTagsAccess: any;
  preApprovedEmailAccess: any;
  commentManagementAccess: any;
  qrManagementAccess: any;
  replyManagementAccess: any;
  newsManagementAccess: any;
  blogsManagementAccess: any;
  public accessPermission;
  industry: string;

  constructor(
    public commonService: CommonService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.industry = this.localStorageService.getToken('industry');
    this.accessPermission = this.commonService.decrypt(
      this.localStorageService.getToken('role_permission')
    );
    this.openMenu();
    this.openMenuItem();

    //common
    this.userAccess = this.accessPermission.userAccess.viewList
      ? this.accessPermission.userAccess.viewList
      : null;
    this.rolesAccess = this.accessPermission.rolesAccess.viewList
      ? this.accessPermission.rolesAccess.viewList
      : null;
    this.regionManagementAccess = this.accessPermission.regionManagementAccess
      .viewList
      ? this.accessPermission.regionManagementAccess.viewList
      : null;

    this.incidentManagementAccess = this.accessPermission
      .incidentManagementAccess.viewList
      ? this.accessPermission.incidentManagementAccess.viewList
      : null;
    this.solutionManagementAccess = this.accessPermission
      .solutionManagementAccess.viewList
      ? this.accessPermission.solutionManagementAccess.viewList
      : null;
    this.domainManagementAccess = this.accessPermission.domainManagementAccess
      .viewList
      ? this.accessPermission.domainManagementAccess.viewList
      : null;
    this.newsManagementAccess = this.accessPermission.newsManagementAccess
      ?.viewList
      ? this.accessPermission.newsManagementAccess.viewList
      : null;
    this.blogsManagementAccess = this.accessPermission.blogsManagementAccess
      ?.viewList
      ? this.accessPermission.blogsManagementAccess.viewList
      : null;
    this.tagManagementAccess = this.accessPermission.tagManagementAccess
      .viewList
      ? this.accessPermission.tagManagementAccess.viewList
      : null;
    this.reachUsManagementAccess = this.accessPermission.reachUsManagementAccess
      .viewList
      ? this.accessPermission.reachUsManagementAccess.viewList
      : null;
    this.postManagementAccess = this.accessPermission.postManagementAccess
      .viewList
      ? this.accessPermission.postManagementAccess.viewList
      : null;
    this.adminUserAccess = this.accessPermission.adminUserAccess.viewList
      ? this.accessPermission.adminUserAccess.viewList
      : null;
    this.cmsPagesAccess = this.accessPermission.cmsPagesAccess.viewList
      ? this.accessPermission.cmsPagesAccess.viewList
      : null;
    this.commentManagementAccess = this.accessPermission.commentManagementAccess
      .viewList
      ? this.accessPermission.commentManagementAccess.viewList
      : null;
    this.qrManagementAccess = this.accessPermission.qrCampaignManagementAccess
      .viewList
      ? this.accessPermission.qrCampaignManagementAccess.viewList
      : null;
    this.requestedDeleteIncidentAccess = this.accessPermission
      .requestedDeleteIncidentAccess.viewList
      ? this.accessPermission.requestedDeleteIncidentAccess.viewList
      : null;
    this.requestedDeleteSolutionAccess = this.accessPermission
      .requestedDeleteSolutionAccess.viewList
      ? this.accessPermission.requestedDeleteSolutionAccess.viewList
      : null;
    this.recommendedDomainManagementAccess = this.accessPermission
      .recommendedDomainManagementAccess.viewList
      ? this.accessPermission.recommendedDomainManagementAccess.viewList
      : null;
    this.preApprovedEmailTagsAccess = this.accessPermission
      .preApprovedEmailTagManagementAccess.viewList
      ? this.accessPermission.preApprovedEmailTagManagementAccess.viewList
      : null;
    this.preApprovedEmailAccess = this.accessPermission
      .preApprovedEmailsManagementAccess.viewList
      ? this.accessPermission.preApprovedEmailsManagementAccess.viewList
      : null;
    this.replyManagementAccess = this.accessPermission.replyManagementAccess
      ?.viewList
      ? this.accessPermission.replyManagementAccess.viewList
      : null;

    //healthcare
    if (this.industry === 'healthcare') {
      this.departmentManagementAccess = this.accessPermission
        .departmentManagementAccess.viewList
        ? this.accessPermission.departmentManagementAccess.viewList
        : null;
      this.designationManagementAccess = this.accessPermission
        .designationManagementAccess.viewList
        ? this.accessPermission.designationManagementAccess.viewList
        : null;
      this.healthcareRoleManagementAccess = this.accessPermission
        .healthcareRoleManagementAccess.viewList
        ? this.accessPermission.healthcareRoleManagementAccess.viewList
        : null;
      this.positionManagementAccess = this.accessPermission
        .positionManagementAccess.viewList
        ? this.accessPermission.positionManagementAccess.viewList
        : null;
      this.subRegionManagementAccess = this.accessPermission
        .subRegionManagementAccess.viewList
        ? this.accessPermission.subRegionManagementAccess.viewList
        : null;
    }

    //aviation
    if (this.industry === 'aviation') {
      this.expertiseManagementAccess = this.accessPermission
        .expertiseManagementAccess.viewList
        ? this.accessPermission.expertiseManagementAccess.viewList
        : null;
      this.trainingManagementAccess = this.accessPermission
        .trainingManagementAccess.viewList
        ? this.accessPermission.trainingManagementAccess.viewList
        : null;
      this.mechanicalFailureManagementAccess = this.accessPermission
        .mechanicalFailureManagementAccess.viewList
        ? this.accessPermission.mechanicalFailureManagementAccess.viewList
        : null;
      this.airlineManagementAccess = this.accessPermission
        .airlineManagementAccess.viewList
        ? this.accessPermission.airlineManagementAccess.viewList
        : null;
      this.placeOfServiceManagementAccess = this.accessPermission
        .placeOfServiceManagementAccess.viewList
        ? this.accessPermission.placeOfServiceManagementAccess.viewList
        : null;
    }
  }

  closeSidebar(subMenu) {
    var sidebar = Array.from(
      document.getElementsByClassName(
        'sidebar'
      ) as HTMLCollectionOf<HTMLElement>
    );
    sidebar[0].style.display = 'none';
    this.openCloseSubMenu(subMenu);
  }

  closeSidebarMenuItem(menuItem) {
    var sidebar = Array.from(
      document.getElementsByClassName(
        'sidebar'
      ) as HTMLCollectionOf<HTMLElement>
    );
    sidebar[0].style.display = 'none';
    if (menuItem != null) {
      var elements = Array.from(document.getElementsByClassName('active-link'));
      elements.forEach((element) => {
        element.classList.remove('active-link');
      });

      var activeElement = document.getElementById(menuItem);
      activeElement.classList.add('active-link');
      this.localStorageService.setToken('menuItem', menuItem);
    }
  }

  openCloseMenuItem(menuItem) {
    var elements = Array.from(document.getElementsByClassName('active-link'));
    elements.forEach((element) => {
      element.classList.remove('active-link');
    });

    var activeElement = document.getElementById(menuItem);
    if (activeElement != null) activeElement.classList.add('active-link');
  }

  openCloseSubMenu(subMenu) {
    var blog = document.getElementById('blog');
    this.localStorageService.setToken('subMenu', subMenu);

    if (subMenu == 'subUserMenu') {
      this.subUserMenu = !this.subUserMenu;
      this.subReportMenu =
        this.solutionMenu =
        this.incidentMenu =
        this.postMenu =
        this.dashboard =
        this.subContentMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.emailMenu =
          false;
    } else if (subMenu == 'incidentMenu') {
      this.incidentMenu = !this.incidentMenu;
      this.subReportMenu =
        this.solutionMenu =
        this.dashboard =
        this.postMenu =
        this.subReportMenu =
        this.roles =
        this.subUserMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.emailMenu =
          false;
    } else if (subMenu == 'solutionMenu') {
      console.log('called');
      this.solutionMenu = !this.solutionMenu;
      this.subReportMenu =
        this.incidentMenu =
        this.dashboard =
        this.postMenu =
        this.subReportMenu =
        this.roles =
        this.subUserMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.emailMenu =
          false;
    } else if (subMenu == 'subReportMenu') {
      this.subReportMenu = !this.subReportMenu;
      this.incidentMenu =
        this.solutionMenu =
        this.dashboard =
        this.postMenu =
        this.subUserMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.emailMenu =
          false;
    } else if (subMenu == 'subContentMenu') {
      this.subContentMenu = !this.subContentMenu;
      this.subReportMenu =
        this.solutionMenu =
        this.incidentMenu =
        this.dashboard =
        this.subUserMenu =
        this.postMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.emailMenu =
          false;
    } else if (subMenu == 'dashboard') {
      this.dashboard = !this.dashboard;
      this.subReportMenu =
        this.solutionMenu =
        this.incidentMenu =
        this.subContentMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.postMenu =
        this.subUserMenu =
        this.emailMenu =
          false;
      this.closeSidebarMenuItem('dashboard');
    } else if (subMenu == 'emailTemplate') {
      this.emailTemplate = !this.emailTemplate;
      this.subContentMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.postMenu =
        this.subUserMenu =
        this.emailMenu =
          false;
      this.closeSidebarMenuItem('emailTemplate');
    } else if (subMenu == 'master') {
      this.master = !this.master;
      this.subContentMenu =
        this.subSettingsMenu =
        this.postMenu =
        this.subBlogMenu =
        this.subUserMenu =
        this.emailMenu =
          false;
      this.closeSidebarMenuItem('master');
    } else if (subMenu == 'roles') {
      this.roles = !this.roles;
      this.subContentMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.postMenu =
        this.subUserMenu =
        this.emailMenu =
          false;
      this.closeSidebarMenuItem('roles');
    } else if (subMenu == 'subSettingsMenu') {
      this.subSettingsMenu = !this.subSettingsMenu;
      this.subContentMenu = this.subUserMenu = this.subBlogMenu = false;
      this.postMenu = false;
    } else if (subMenu == 'subBlogMenu') {
      this.subBlogMenu = !this.subBlogMenu;
      if (blog.style.display == 'none') {
        blog.style.display = 'block';
      } else {
        blog.style.display = 'none';
      }
      this.subContentMenu = this.subSettingsMenu = this.subUserMenu = false;
    } else if (subMenu == 'postMenu') {
      this.postMenu = !this.postMenu;
      this.incidentMenu =
        this.solutionMenu =
        this.subReportMenu =
        this.dashboard =
        this.subReportMenu =
        this.roles =
        this.subUserMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.emailMenu =
          false;
    } else if (subMenu == 'emailMenu') {
      this.emailMenu = !this.emailMenu;
      this.incidentMenu =
        this.solutionMenu =
        this.subReportMenu =
        this.dashboard =
        this.subReportMenu =
        this.roles =
        this.subUserMenu =
        this.subSettingsMenu =
        this.subBlogMenu =
        this.postMenu =
          false;
    }
  }

  myFunctionOne() {
    this.openMenu();
  }

  openMenu() {
    var menu = this.localStorageService.getToken('subMenu');
    if (menu != '' && menu != undefined) {
      if (
        (menu == 'subUserMenu' && !this.subUserMenu) ||
        (menu == 'subContentMenu' && !this.subContentMenu) ||
        (menu == 'subSettingsMenu' && !this.subSettingsMenu) ||
        (menu == 'subBlogMenu' && !this.subBlogMenu)
      ) {
        this.openCloseSubMenu(menu);
      }
    }
  }

  openMenuItem() {
    var item = this.localStorageService.getToken('menuItem');
    this.openCloseMenuItem(item);
  }
}
