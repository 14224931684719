// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration='staging' && node server.js` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  environmentName: 'staging',
  port: 4070,
  downloadFileUrl: 'https://staging-api.dicerra.com/public/',
  imagePath: 'https://staging-api.dicerra.com',
  //: https://dicerraapi.node.indianic.com/docs/
  apiUrl: 'https://staging-api.dicerra.com/api/',
  //https://dicerraapi.node.indianic.com
  uploadImgUrl: 'https://staging-api.dicerra.com/public/upload/images/',
  s3Url: 'https://dicerra-staging-bucket.s3.amazonaws.com/',
};
